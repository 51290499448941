import { DownloadOutlined } from '@ant-design/icons';
import {
  downloadFileWithCustomName,
  getSingleTranslation,
  useAppUserStore,
  useFanGroupStore,
  useGenerateFGReportUrl,
  useGenerateFansReportUrl,
  useGenerateOlympicsReportUrl,
  useJob,
} from '@seaters-app/data-access';
import {
  Avatar,
  Button,
  Flex,
  Form,
  Input,
  Select,
  Space,
  Spin,
  Typography,
  message,
} from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Alerts from '../supportActions/components/Alerts';
import { Job, UserRole } from '@seaters-app/constants';
import useUploadXlsx from '../supportActions/hooks/useUploadXlsx';
import XlsxUpload from '../supportActions/XlsxUpload';
import { useFGBadges } from '../fan-groups/[id]/badges/useFGBadges';
import { DatePicker } from '@seaters-app/ui';
const { Title, Text } = Typography;

const reportsFGO = [
  {
    label: 'report_fg_gen-btn',
    value: 'ticketAssignmentsReportJob',
  },
];

const reportsAdmin = [
  {
    label: 'report_fg_gen-btn',
    value: 'ticketAssignmentsReportJob',
  },
  {
    label: 'export_fans_report_label',
    value: 'fangroupFansExport',
  },
  {
    label: 'export_fan_session_report_label',
    value: 'olympicsFanSessionExport',
  },
];

function FGReporting() {
  const { t } = useTranslation();
  const { fanGroup } = useFanGroupStore();
  const [messageApi, contextHolder] = message.useMessage();
  const { user } = useAppUserStore();

  const isAdmin = user?.roles.includes(UserRole.ADMIN);

  const actionsOptions = (isAdmin ? reportsAdmin : reportsFGO).map(
    (report) => ({
      label: t(report.label),
      value: report.value,
    })
  );

  const { handleUploadFile, ticketsXlsxKey, contactsXlsxKey } =
    useUploadXlsx(messageApi);

  const [jobId, setJobId] = useState<string | null>(null);

  const { mutate: generateFGReportUrl } = useGenerateFGReportUrl();
  const { mutate: generateFansReportUrl } = useGenerateFansReportUrl();
  const { mutate: generateOlympicsReportUrl } = useGenerateOlympicsReportUrl();

  const [form] = Form.useForm();

  const reportType = Form.useWatch('reportType', form);

  const [xlsxUrl, setXlsxUrl] = useState<string | null>(null);
  const [isGenerating, setIsGenerating] = useState(false);

  const { badges: fanGroupBadges } = useFGBadges();

  const badgesOptions = fanGroupBadges?.map((badge) => ({
    label: getSingleTranslation(badge.name),
    value: badge.id,
    avatar: badge.displayedLogoUrl,
  }));

  const { data: job } = useJob(jobId || '');

  const getFileName = (type?: Job['type']) => {
    switch (type) {
      case 'ticketAssignmentsReportJob':
        return `${
          getSingleTranslation(fanGroup?.name ?? []) ?? ''
        }-ticket-assignments-${dayjs(new Date()).format('YYYY-MM-DD')}`;

      case 'fangroupFansExport':
        return `${
          getSingleTranslation(fanGroup?.name ?? []) ?? ''
        }-fans-${dayjs(new Date()).format('YYYY-MM-DD')}`;

      case 'olympicsFanSessionExport':
        return `${
          getSingleTranslation(fanGroup?.name ?? []) ?? ''
        }-fan-session-${dayjs(new Date()).format('YYYY-MM-DD')}`;

      default:
        return 'report';
    }
  };

  const handleDownload = () => {
    downloadFileWithCustomName(xlsxUrl ?? '', getFileName(job?.type));
  };

  const onSuccess = (res: Job) => {
    setIsGenerating(true);
    setJobId(res.id);
    messageApi.destroy('startingJob');
    messageApi.open({
      key: 'startingJob',
      type: 'success',

      content: t('message_job_started_successfully'),
    });
  };

  const onFinish = (values: {
    fangroupSlug: string;
    xlsxKey?: string;
    badgeIds?: string[];
    startDate?: string;
    subGroups: string;
    subGroupIds: string;
    reportType:
      | 'ticketAssignmentsReportJob'
      | 'fangroupFansExport'
      | 'olympicsFanSessionExport'
      | 'surveyAnswersExport';
  }) => {
    messageApi.open({
      key: 'startingJob',
      type: 'loading',
      content: t('message_starting_job'),
      duration: 0,
    });
    setXlsxUrl('');

    if (values.reportType === 'ticketAssignmentsReportJob') {
      generateFGReportUrl(
        {
          fangroupSlug: fanGroup?.slug ?? '',
        },
        {
          onSuccess,
        }
      );
    }
    if (values.reportType === 'fangroupFansExport') {
      generateFansReportUrl(
        {
          fangroupSlug: fanGroup?.slug ?? '',
          badgeIds: values.badgeIds ?? [],
          subGroups: values.subGroups,
          subGroupIds: values.subGroupIds,
        },
        {
          onSuccess,
        }
      );
    }
    if (
      values.reportType === 'olympicsFanSessionExport' &&
      ticketsXlsxKey &&
      contactsXlsxKey
    ) {
      generateOlympicsReportUrl(
        {
          fangroupSlug: fanGroup?.slug ?? '',
          ticketsXlsxKey: ticketsXlsxKey,
          contactsXlsxKey: contactsXlsxKey,
          startDate: values.startDate,
          badgeIds: values.badgeIds ?? [],
        },
        {
          onSuccess,
        }
      );
    }
  };

  useEffect(() => {
    if (
      job?.latestXlsxSingedUrl &&
      (!ticketsXlsxKey || ticketsXlsxKey !== job?.latestXlsxSingedUrl) &&
      !['running', 'pending'].includes(job.status)
    ) {
      setXlsxUrl(job?.latestXlsxSingedUrl);
      setIsGenerating(false);
    }
  }, [job?.latestXlsxSingedUrl]);

  useEffect(() => {
    if (job?.status === 'failed') {
      messageApi.open({
        key: 'jobFailed',
        type: 'error',
        content: t('report_generation_error'),
      });
      setIsGenerating(false);
    }
  }, [job?.status]);

  return (
    <Flex
      vertical
      style={{
        padding: 24,
      }}
    >
      {contextHolder}
      <Spin spinning={isGenerating} tip={t('report_generation_notification')}>
        <Title level={2}>{t('admin_support_reporting')}</Title>
        <Form
          initialValues={{}}
          layout="vertical"
          name="bulk_requests"
          form={form}
          style={{
            maxWidth: 600,
          }}
          onFinish={onFinish}
        >
          <Form.Item
            label={t('admin_support_report_to_generate')}
            name="reportType"
            required
            rules={[
              {
                required: true,
                message: t('report_type_validation_message'),
              },
            ]}
          >
            <Select
              placeholder={t('admin_support_bulk_requests_select_action')}
              allowClear
              options={actionsOptions}
              onSelect={() => {
                form.resetFields(['badgeIds']);
                setXlsxUrl('');
              }}
            />
          </Form.Item>
          {reportType === 'fangroupFansExport' && (
            <>
              <Form.Item
                label={t('admin_subgroups_label')}
                name="subGroups"
                rules={[
                  {
                    required: true,
                    message: t('general_label_required'),
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={t('admin_subgroups_ids_label')}
                name="subGroupIds"
                rules={[
                  {
                    required: true,
                    message: t('general_label_required'),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </>
          )}
          {reportType === 'olympicsFanSessionExport' ||
          reportType === 'fangroupFansExport' ? (
            <Form.Item
              label={t('admin_badges')}
              name="badgeIds"
              rules={[
                {
                  required: true,
                  message: t('general_label_required'),
                },
              ]}
            >
              <Select
                showSearch
                allowClear
                optionFilterProp="label"
                mode="multiple"
                placeholder={t('search_badge_placeholder')}
                options={badgesOptions}
                optionRender={(option) => {
                  return (
                    <Space>
                      <Avatar src={option?.data.avatar} size="small" />
                      {option?.data.label}
                    </Space>
                  );
                }}
              />
            </Form.Item>
          ) : null}
          {reportType === 'olympicsFanSessionExport' && (
            <Form.Item
              label={<Text strong>{t('matrix_header_date')}</Text>}
              name="startDate"
            >
              <DatePicker
                format="DD/MM/YYYY HH:mm"
                style={{ width: '100%' }}
                showTime={{
                  defaultValue: dayjs('00:00', 'HH:mm'),
                  format: 'HH:mm',
                }}
              />
            </Form.Item>
          )}
          {reportType === 'olympicsFanSessionExport' ? (
            <Form.Item label={t('upload_tickets_file_label')}>
              <XlsxUpload
                messageApi={messageApi}
                onUpload={(file) => handleUploadFile(file, 'tickets')}
              />
            </Form.Item>
          ) : null}
          {reportType === 'olympicsFanSessionExport' ? (
            <Form.Item label={t('upload_contacts_file_label')}>
              <XlsxUpload
                messageApi={messageApi}
                onUpload={(file) => handleUploadFile(file, 'contacts')}
              />
            </Form.Item>
          ) : null}
          <Form.Item>
            <Button
              htmlType="submit"
              style={{ width: '250px' }}
              type="primary"
              disabled={
                (!ticketsXlsxKey || !contactsXlsxKey) &&
                reportType === 'olympicsFanSessionExport'
              }
            >
              {t('report_fg_gen-btn')}
            </Button>
          </Form.Item>
          {!!xlsxUrl && (
            <Form.Item>
              <Button
                onClick={handleDownload}
                style={{ width: '250px' }}
                type="text"
                icon={<DownloadOutlined rev={undefined} />}
              >
                {t('download_report_btn_text')}
              </Button>
            </Form.Item>
          )}
          <Alerts job={job} />
        </Form>
      </Spin>
    </Flex>
  );
}

export default FGReporting;
