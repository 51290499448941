import {
  LOCATION_STATES,
  LoginTypeEnum,
  LookEntity,
  routes,
  SEATERS_COOKIES_ACCEPTED,
  usersKeys,
} from '@seaters-app/constants';
import { queryClient, useCookiesStore } from '@seaters-app/data-access';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CookieConsent } from 'react-cookie-consent';
import { theme } from 'antd';
import { useLocation } from 'react-router-dom';
import { isWebView } from 'apps/seaters/src/utils';

export function CookiesBanner() {
  const { t } = useTranslation();
  const { token } = theme.useToken();

  const { setCookies } = useCookiesStore();

  const webView = isWebView();

  const look = queryClient.getQueryData<LookEntity>(usersKeys.look());

  const { state: locationState } = useLocation();

  const oauthOption = look?.login.find(
    (login) => login.type === LoginTypeEnum.OAUTH
  );

  const isRedirectingToSSO =
    oauthOption &&
    locationState?.[LOCATION_STATES.REDIRECT_PATH]?.pathname.includes(
      routes.waitingList
    );

  const cookiesAcceptedData = document.cookie.includes(
    `${SEATERS_COOKIES_ACCEPTED}=true`
  );

  const cookieConsentExpirationTime = 60 * 60 * 24 * 30 * 6;

  const [cookiesAccepted, setCookiesAccepted] = useState(cookiesAcceptedData);

  const handleCookiesAccepted = () => {
    setCookiesAccepted(true);
    document.cookie = `${SEATERS_COOKIES_ACCEPTED}=true; max-age=${cookieConsentExpirationTime}; path=/`;
    setCookies(); // set cookies in zustand store, to trigger  TermsAndConditionsModal
  };

  const DEV = import.meta.env.MODE === 'development';

  return (
    <CookieConsent
      customButtonProps={{
        'data-testid': 'cookiesAcceptButton',
      }}
      visible={
        !cookiesAccepted &&
        !webView &&
        localStorage.getItem(SEATERS_COOKIES_ACCEPTED) === null &&
        !DEV &&
        !window.location.pathname.includes(routes.security) &&
        !window.location.pathname.includes(routes.surveyAfterEvent) &&
        !isRedirectingToSSO
          ? 'show'
          : 'hidden'
      }
      buttonText={t('general_close-btn')}
      onAccept={handleCookiesAccepted}
      buttonStyle={{
        backgroundColor: token.colorPrimary,
        color: token.colorBgBase,
        fontSize: 16,
        padding: '10px 20px',
        borderRadius: 5,
      }}
      style={{ padding: 40 }}
    >
      <div style={{ fontSize: 16, lineHeight: 2 }}>
        {t('poab_cookies_accept-description')}{' '}
        <a
          href={'https://www.seaters.ai/privacy#cookies'}
          target="_blank"
          rel="noreferrer"
          style={{ color: token.colorPrimary }}
        >
          {t('cookie_policy_title')}
        </a>
      </div>
    </CookieConsent>
  );
}

export default CookiesBanner;
