import {
  ContentType,
  CreateBulkAccessCodesBody,
  CreateBulkWishListsBody,
  CreateBulkBadgesBody,
  Job,
  DistributeBulkPositionsBody,
  UpdateBulkAccessCodesBody,
  AssignUserRolesBody,
  RemoveAccessCodesBody,
  SurveyExtensionPoint,
  SeatStatus,
  WLPositionStatus,
} from '@seaters-app/constants';
import { supportApi } from '../axios-instance/supportApi';

export const createUploadURL = async (
  contentType: ContentType
): Promise<{
  url: string;
  key: string;
}> => {
  return supportApi.post('/files', { contentType });
};

export const getUploadURL = async (
  key: string
): Promise<{
  key: string;
  url: string;
}> => {
  return supportApi.get(`/files/${key}`);
};

export const createBulkWishLists = async (
  body: CreateBulkWishListsBody
): Promise<Job> => {
  return supportApi.post('/batch-upload/wish-lists', body);
};

export const createBulkAccessCodes = async (
  body: CreateBulkAccessCodesBody
): Promise<Job> => {
  return supportApi.post('/batch-upload/codes', body);
};

export const createBulkBadges = async (
  body: CreateBulkBadgesBody
): Promise<Job> => {
  return supportApi.post('/batch-upload/badges', body);
};

export const removeBulkBadges = async (
  body: CreateBulkBadgesBody
): Promise<Job> => {
  return supportApi.post('/batch-upload/badges/delete', body);
};

export const distributeBulkPositions = async (
  body: DistributeBulkPositionsBody
): Promise<Job> => {
  return supportApi.post('/batch-upload/position-distributions', body);
};

export const updateBulkAccessCodes = async (
  body: UpdateBulkAccessCodesBody
): Promise<Job> => {
  return supportApi.post('/batch-upload/enhanced-access-codes-update', body);
};

export const assignUserRoles = async (
  body: AssignUserRolesBody
): Promise<Job> => {
  return supportApi.post('/batch-upload/roles-assignment', body);
};

export const removeAccessCodes = async (
  body: RemoveAccessCodesBody
): Promise<Job> => {
  return supportApi.post('/batch-upload/remove-access-codes', body);
};

export const generateFGReportUrl = async (body: {
  fangroupSlug: string;
}): Promise<Job> => {
  return supportApi.post('/reports/ticket-assignments', body);
};

export const generatePDFTickets = async (body: {
  xlsxKey: string;
  inputTicketsKey: string;
}): Promise<Job> => {
  return supportApi.post('/pdf-tickets/codes', body);
};

export const generateFansReportUrl = async (body: {
  fangroupSlug: string;
  badgeIds: string[];
  subGroups: string;
  subGroupIds: string;
}): Promise<Job> => {
  return supportApi.post('/reports/fangroup-fans-export', body);
};

export const generateOlympicsReportUrl = async (body: {
  fangroupSlug: string;
  ticketsXlsxKey: string;
  contactsXlsxKey: string;
  badgeIds: string[];
  startDate?: string;
}): Promise<Job> => {
  return supportApi.post('/reports/olympics/fan-session-export', body);
};

export const generateSurveyReportUrl = async (body: {
  fangroupSlug: string;
  wishlistId: string;
  extensionPoint: SurveyExtensionPoint;
  positionStatus: WLPositionStatus;
  userLanguage: string;
}): Promise<Job> => {
  return supportApi.post('/reports/survey-answers-export', body);
};
// job id: 7e173555-fc04-4ef2-a5ac-a1b7bb577ee3
export const fetchJob = async (jobId: string): Promise<Job> => {
  return supportApi.get(`/job/${jobId}`);
};

export const updateJob = async (
  jobId: string,
  body: {
    action: 'moveNext' | 'cancel' | 'retryCurrent';
  }
): Promise<Job> => {
  return supportApi.patch(`/job/${jobId}/transitions`, body);
};

// https://api-gw.qa-seaters.com/support/api/docs#tag/job/PATCH/api/job/{id}/xlsx

export const updateJobXLSX = async (
  jobId: string,
  body: {
    key: string;
  }
): Promise<Job> => {
  return supportApi.patch(`/job/${jobId}/xlsx`, body);
};
