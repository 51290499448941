import {
  LOCATION_STATES,
  LookEntity,
  SESSION_STORAGE_VERIFICATION,
  UserRole,
  routes,
} from '@seaters-app/constants';
import {
  getSessionToken,
  getSlugFromUrl,
  queryClient,
  useAppUserStore,
  useUserProfile,
} from '@seaters-app/data-access';
import { useEffect, useMemo } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import { AccessDenied, Loader, primaryColor } from '@seaters-app/ui';
import useCurrentGroupRoles from '../hooks/useCurrentGroupRoles';
import { useFanGroupSlug } from '../../pages/hooks';
import { CookiesBanner } from '@seaters-app/ui-shared';

const PrivateRoutes = ({ roles }: { roles?: UserRole[] }) => {
  const location = useLocation();

  const { i18n, ready } = useTranslation();
  const auth = getSessionToken();
  const fanGroupData: LookEntity | undefined = queryClient.getQueryData([
    'users',
    'look',
  ]);

  const { user, setUser } = useAppUserStore();

  const { isLoading: isGroupsListsLoading, hasIntersections } =
    useCurrentGroupRoles(roles);

  const slug = getSlugFromUrl();

  const userData = sessionStorage.getItem(SESSION_STORAGE_VERIFICATION);

  const isAdmin = useMemo(() => {
    if (!userData) {
      return false;
    }
    return JSON.parse(userData).userData.roles.includes(UserRole.ADMIN);
  }, [userData]);

  const isAdminOnly =
    userData &&
    JSON.parse(userData).userData.roles.includes(UserRole.ADMIN) &&
    JSON.parse(userData).userData.roles.length === 2; // because all users have FAN role

  const { data: userProfile, isLoading: isProfileLoading } =
    useUserProfile(true);

  const { customLightTheme, detectedSlug } = useFanGroupSlug();

  const isLoading =
    !ready ||
    (!!detectedSlug && customLightTheme.token.colorPrimary === primaryColor);

  useEffect(() => {
    if (userProfile && userProfile?.language !== i18n.language) {
      i18n.changeLanguage(userProfile.language);
      i18n.init();
    }
  }, [userProfile?.language]);

  useEffect(() => {
    if (!user) {
      setUser(userProfile);
    }
  }, [userProfile]);

  return auth ? (
    <QueryParamProvider adapter={ReactRouter6Adapter}>
      {isProfileLoading ||
      (slug && !fanGroupData) ||
      (isGroupsListsLoading && !isAdminOnly) ? (
        <Loader show />
      ) : hasIntersections || isAdmin ? (
        <Outlet />
      ) : (
        <AccessDenied />
      )}
      {!isLoading && <CookiesBanner />}
    </QueryParamProvider>
  ) : (
    <Navigate
      to={slug ? routes.landing : routes.login}
      state={{
        [LOCATION_STATES.REDIRECT_PATH]:
          location?.state?.redirectPath ?? location,
      }}
    />
  );
};

export { PrivateRoutes };
